import {
  ADD_ACCOUNT,
  ADD_ACCOUNT_FAIL,
  ADD_ACCOUNT_SUCCESS,
  CLEAR_REDUX_ACCOUNTS_STATE,
  CREATE_STRIPE_SESSION,
  CREATE_STRIPE_SESSION_FAIL,
  CREATE_STRIPE_SESSION_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_FAIL,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_CALENDARS,
  FETCH_CALENDARS_FAIL,
  FETCH_CALENDARS_SUCCESS,
  MAKE_DEFAULT,
  MAKE_DEFAULT_FAIL,
  MAKE_DEFAULT_SUCCESS,
  RESYNC_INTERACTIONS,
  RESYNC_INTERACTIONS_START,
  RESYNC_INTERACTIONS_SUCCESS,
  SET_INITIAL_REDUX_ACCOUNTS_STATE,
  UPDATE_CRITERIA,
  UPDATE_CRITERIA_FAIL,
  UPDATE_CRITERIA_SUCCESS,
  UPDATE_SIGNATURE,
  UPDATE_SIGNATURE_FAIL,
  UPDATE_SIGNATURE_SUCCESS,
} from "./Accounts.types"
import { LOGOUT_SUCCESS } from "../User/User.types"
import { REHYDRATE } from "redux-persist/es/constants"

const INITIAL_STATE = {
  accounts: null,
  loading: false,
  criteriaUpdating: false,
  initializingID: null,
  session_creating: false,
  stripe_session_url: null,
  split_messages_left_today: 0,
  is_user_active: true,
}

const updateAccounts = (state, action) => {
  let updatingAccount = state.accounts.find(
    (account) => account.id === action.account.id
  )
  let othersAccounts = state.accounts
    .filter((account) => account.id !== action.account.id)
    .map((account) => ({ ...account, default: false }))

  updatingAccount = { ...updatingAccount, default: true }

  return [updatingAccount, ...othersAccounts].sort((a, b) =>
    a === b ? 0 : a ? 1 : -1
  )
}

export const accountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        session_creating: false,
        stripe_session_url: null,
      }
    case FETCH_ACCOUNTS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.accounts.sort((a, b) => (a === b ? 0 : a ? -1 : 1)),
        split_messages_left_today: action.split_messages_left_today,
        is_user_active: action.is_user_active,
        loading: false,
        criteriaUpdating: false,
      }
    case FETCH_ACCOUNTS_FAIL:
      return {
        ...state,
        loading: false,
        criteriaUpdating: false,
      }
    case ADD_ACCOUNT:
      return {
        ...state,
        loading: true,
      }
    case ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: action.account.sort((a, b) => (a === b ? 0 : a ? -1 : 1)),
        loading: false,
      }
    case ADD_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
      }
    case MAKE_DEFAULT:
      return {
        ...state,
        loading: true,
      }
    case MAKE_DEFAULT_SUCCESS:
      return {
        ...state,
        accounts: updateAccounts(state, action),
        loading: false,
      }
    case MAKE_DEFAULT_FAIL:
      return {
        ...state,
        loading: false,
      }
    case DELETE_ACCOUNT:
      return {
        ...state,
        accounts: [
          ...state.accounts.map((a) => {
            if (a.nylas_id === action.id) {
              return { ...a, removing: true }
            } else {
              return a
            }
          }),
        ],
        loading: true,
      }
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accounts: [
          ...state.accounts.map((account) => {
            if (account.nylas_id === action.id) {
              return { ...account, removing: false, mark_for_deletion: true }
            } else {
              return account
            }
          }),
        ],
        loading: false,
      }
    case DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        accounts: [
          ...state.accounts.map((a) => {
            return { ...a, removing: false }
          }),
        ],
      }
    case RESYNC_INTERACTIONS:
      return {
        ...state,
        initializingID: action.id,
      }
    case RESYNC_INTERACTIONS_START:
      return {
        ...state,
        initializingID: null,
      }
    case RESYNC_INTERACTIONS_SUCCESS:
      return {
        ...state,
        initializingID: null,
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case UPDATE_SIGNATURE:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_SIGNATURE_SUCCESS:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.id === action.account_id) {
            account.signature = action.signature
            return account
          } else {
            return account
          }
        }),
        loading: false,
      }
    case UPDATE_SIGNATURE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_CRITERIA:
      return {
        ...state,
        criteriaUpdating: true,
      }
    case UPDATE_CRITERIA_SUCCESS:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.id === action.account_id) {
            account.criteria = action.criteria
            return account
          } else {
            return account
          }
        }),
        criteriaUpdating: false,
      }
    case UPDATE_CRITERIA_FAIL:
      return {
        ...state,
        criteriaUpdating: false,
      }
    case CLEAR_REDUX_ACCOUNTS_STATE:
      return {}
    case SET_INITIAL_REDUX_ACCOUNTS_STATE:
      return { ...INITIAL_STATE }
    case FETCH_CALENDARS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_CALENDARS_SUCCESS:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.id === action.account_id) {
            account.calendars = action.calendars
            account.state = action.state
            return account
          } else {
            return account
          }
        }),
        loading: false,
      }
    case FETCH_CALENDARS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case CREATE_STRIPE_SESSION:
      return {
        ...state,
        stripe_session_url: null,
        session_creating: true,
      }
    case CREATE_STRIPE_SESSION_SUCCESS:
      return {
        ...state,
        stripe_session_url: action.session_url,
        session_creating: false,
      }
    case CREATE_STRIPE_SESSION_FAIL:
      return {
        ...state,
        stripe_session_url: null,
        session_creating: false,
      }
    default:
      return state
  }
}
