import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./SectionActivity.styles.scss"
import Icon, { LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import * as _ from "lodash"
import InteractionItem from "./InteractionItem/InteractionItem.component"
import moment from "moment/moment"
import PlusInSquareIcons from "../../../../Icons/PlusInSquare.icons"
import { List, Pagination, Tooltip } from "antd"
import { openDrawerTab } from "../../../../../redux/App/App.actions"
import { ReactComponent as UserIcon } from "../../../../Icons/UserIcon.svg"
import RelatableLoader from "../../../../UI/RelatableLoader/RelatableLoader.component"

const SectionActivity = ({
  contact,
  loadMoreInteractions,
  loadMoreEvents,
  deletable = true,
  hideAddButton = false,
}) => {
  const [interactionsPage, setInteractionsPage] = useState(1)
  const [eventsPage, setEventsPage] = useState(1)

  const user = useSelector((state) => state.UserState)
  const loadingInteractions = useSelector(
    (state) => state.InteractionsState.fetching_interactions
  )
  const loadingEvents = useSelector(
    (state) => state.InteractionsState.fetching_events
  )

  const dispatch = useDispatch()

  const formatDate = (interaction) => {
    if (interaction.last_message_timestamp) {
      return moment
        .unix(interaction.last_message_timestamp)
        .tz(user.time_zone)
        .format("YYYY-MM-DD-HH")
    } else if (interaction.last_message_received) {
      return moment(interaction.last_message_received)
        .tz(user.time_zone)
        .format("YYYY-MM-DD-HH")
    } else if (interaction.last_message_sent) {
      return moment(interaction.last_message_sent)
        .tz(user.time_zone)
        .format("YYYY-MM-DD-HH")
    }
  }

  const lastCanonicalInteraction = () => {
    let lastInteractions = []
    Object.keys(contact.canonical_participants).length > 0 &&
      Object.keys(contact.canonical_participants).forEach((key) => {
        lastInteractions = [
          ...lastInteractions,
          ...contact.canonical_participants[key],
        ]
      })

    return lastInteractions[0].last_sent
  }

  const renderList = (key) => {
    const spinning = key === "interactions" ? loadingInteractions : loadingEvents
    const page = key === "interactions" ? interactionsPage : eventsPage
    const paginated = _.sortBy(contact[key], (i) => {
      return formatDate(i)
    })
      .reverse()
      .slice(page * 3 - 3, page * 3)
    return (
      <List
        loading={{
          spinning: spinning,
          indicator: (
            <RelatableLoader
              customQuote={"Loading..."}
              quote={true}
              loading={spinning}
            />
          ),
        }}
        dataSource={paginated}
        size={3}
        bordered={false}
        renderItem={(interaction) => (
          <InteractionItem
            contactId={contact.id}
            interaction={
              key === "interactions"
                ? interaction
                : { ...interaction, type: "Event" }
            }
            deletable={deletable}
            resetPage={() =>
              key === "interactions" ? loadingInteractions : loadingEvents
            }
          />
        )}
      />
    )
  }

  return (
    <section id="sectionActivity">
      <h3 className="SectionTitle">
        Activity
        {!hideAddButton && (
          <Tooltip title="Add new meeting" placement="topRight">
            <Icon
              onClick={() => dispatch(openDrawerTab("interaction"))}
              component={PlusInSquareIcons}
            />
          </Tooltip>
        )}
      </h3>
      <div className="SectionActivity">
        <p>Interactions</p>
        {contact.canonical_participants &&
          Object.keys(contact.canonical_participants).length > 0 &&
          lastCanonicalInteraction() &&
          Object.keys(contact.canonical_participants).map((key) => (
            <div className="SectionActivity_TeamInteractions">
              <div className="SectionActivity_TeamInteractions_Icon">
                <Icon component={UserIcon} />
              </div>
              <div>
                <p>
                  <b>{key}</b> communications:
                </p>
                {contact.canonical_participants[key].map((p) => (
                  <p>
                    {p.user_name +
                      ": " +
                      (p.last_sent
                        ? moment(p.last_sent)
                            .tz(user.time_zone)
                            .format("MMM Do, YYYY")
                        : "N/A")}
                  </p>
                ))}
              </div>
            </div>
          ))}
        {contact.interactions_total_entries > 0 ? (
          <div className="SectionActivity_Interactions">
            {renderList("interactions")}
            <div className="Section_Pagination">
              <button
                className="Section_Pagination_More"
                onClick={() => loadMoreInteractions(contact.id)}
              >
                More Interactions
              </button>
              {contact.interactions_total_entries > 3 && (
                <Pagination
                  showSizeChanger={false}
                  current={interactionsPage}
                  onChange={(newPage) => {
                    setInteractionsPage(newPage)
                  }}
                  hideOnSinglePage={true}
                  total={contact.interactions_total_entries}
                  defaultPageSize={3}
                />
              )}
            </div>
          </div>
        ) : (
          <span className="inactive">Interactions have not been added</span>
        )}
      </div>
      <div style={{ margin: "8px 0px 32px" }} className="SectionActivity">
        <p>Events</p>
        {contact.events_total_entries > 0 ? (
          <div className="SectionActivity_Interactions">
            {renderList("events")}
            <div className="Section_Pagination">
              <button
                className="Section_Pagination_More"
                onClick={() => loadMoreEvents(contact.id)}
              >
                More Events
              </button>
              {contact.events_total_entries > 3 && (
                <Pagination
                  showSizeChanger={false}
                  current={eventsPage}
                  onChange={(newPage) => {
                    setEventsPage(newPage)
                  }}
                  hideOnSinglePage={true}
                  total={contact.events_total_entries}
                  defaultPageSize={3}
                />
              )}
            </div>
          </div>
        ) : (
          <span className="inactive">Events have not been added</span>
        )}
      </div>
    </section>
  )
}

SectionActivity.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    interactions: PropTypes.array,
    interactions_total_entries: PropTypes.number,
    interactions_page_token: PropTypes.string,
    events: PropTypes.array,
    events_total_entries: PropTypes.number,
    events_page_token: PropTypes.string,
  }),
  loadMoreInteractions: PropTypes.func,
  loadMoreEvents: PropTypes.func,
  deletable: PropTypes.bool,
  hideAddButton: PropTypes.bool,
}
export default SectionActivity
