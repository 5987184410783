import React, { useEffect, useState } from "react"
import "./DefaultScreen.styles.scss"

import Icon from "@ant-design/icons"
import CaretRightIcon from "../../../../Icons/CaretRight.icons"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import {
  fetchAiDraft,
  fetchTemplate,
  fetchTemplates,
} from "../../../../../redux/Messages/Messages.actions"
import { toggleSuggestedMessagesPanel } from "../../../../../redux/App/App.actions"

const DefaultScreen = ({ exitDefaultScreen }) => {
  const templates = useSelector((state) => state.Messages.templates)
  const [suggestedMessages, setSuggestedMessages] = useState([])
  const dispatch = useDispatch()

  const getPresetTemplates = async () => {
    await axios
      .request({
        url: "/v1/preset_templates",
        method: "GET",
      })
      .then((r) => {
        setSuggestedMessages(r.data.slice(0, 5))
      })
      .catch((e) => {
        setSuggestedMessages([])
      })
  }

  useEffect(() => {
    getPresetTemplates()
    dispatch(fetchTemplates())
    dispatch(toggleSuggestedMessagesPanel(false))
  }, [])

  const renderStartChoiceCard = (description, onClick, icon) => {
    return (
      <div className={"MessageCreator_DefaultScreen_Card"} onClick={onClick}>
        {icon ? (
          <div className={"MessageCreator_DefaultScreen_Card_Icon"}>{icon}</div>
        ) : null}
        <span className={"MessageCreator_DefaultScreen_Card_Text"}>
          {description}
        </span>
        <div className={"MessageCreator_DefaultScreen_Card_Icon"}>
          <Icon color={"#A3A3A1FF"} component={CaretRightIcon} />
        </div>
      </div>
    )
  }

  const onClickSeeMore = (type) => {
    exitDefaultScreen(type)
  }

  return (
    <div className={"MessageCreator_DefaultScreen"}>
      <h3>Choose a message to start with</h3>
      <div className={"MessageCreator_DefaultScreen_Container"}>
        {renderStartChoiceCard("Start with a blank email message", () => {
          exitDefaultScreen()
        })}
      </div>
      <div className={"MessageCreator_DefaultScreen_Container"}>
        <h4>Your previous split messages & templates</h4>
        {templates?.slice(0, 5).map((template) => {
          return renderStartChoiceCard(template.body, () => {
            exitDefaultScreen()
            dispatch(fetchTemplate(template.id))
          })
        })}
        <div className={"MessageCreator_DefaultScreen_LoadMore"}>
          <button onClick={() => onClickSeeMore("templates")}>See more...</button>
        </div>
      </div>
      <div className={"MessageCreator_DefaultScreen_Container"}>
        <h4>Relatable suggestions</h4>
        {suggestedMessages?.map((suggestion) => {
          return renderStartChoiceCard(suggestion.body, () => {
            exitDefaultScreen()
            dispatch(fetchAiDraft(null, null, null, suggestion.id))
          })
        })}
        <div className={"MessageCreator_DefaultScreen_LoadMore"}>
          <button onClick={() => onClickSeeMore("suggestions")}>See more...</button>
        </div>
      </div>
    </div>
  )
}

export default DefaultScreen
