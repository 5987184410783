import React, { useEffect, useRef, useState } from "react"
import "./MessageCreator.styles.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteScheduledSplitMessage,
  sendSplitMessage,
  setMessageDraft,
  updateScheduledSplitMessage,
} from "../../../../redux/Messages/Messages.actions"
import _ from "lodash"
import {
  getFirstNames,
  initSplitMessage,
  injectSignature,
  renderTemplate,
} from "./helpers"
import ReceiversListComponent from "./components/ReceiversList.component"
import moment from "moment/moment"
import SchedulerDateButton from "./components/SchedulerDateButton.component"
import {
  setContactDrawerMode,
  setContactDrawerVisible,
  setInitCC,
  setSplitMessageSenderVisible,
  setVisibleContactID,
  toggleSuggestedMessagesPanel,
} from "../../../../redux/App/App.actions"
import MessageForm from "./components/MessageForm/MessageForm.component"
import AttachmentsUpload from "../../AttachmentsUpload/AttachmentsUpload.component"
import TinyMCUEditor from "../../TinyMCUEditor/TinyMCUEditor"
import DefaultScreen from "./components/DefaultScreen.component"

const MessageCreator = ({
  onCancel,
  onSubmit,
  splitMessage,
  setShowConfetti = () => {},
}) => {
  const dispatch = useDispatch()
  const selected_template = useSelector((state) => state.Messages.selected_template)
  const ai_draft = useSelector((state) => state.Messages.ai_draft)
  const user = useSelector((state) => state.UserState)
  const loading = useSelector((state) => state.Messages.loading)
  const [receivers, setReceivers] = useState([])

  const [includeRelated, setIncludeRelatedContacts] = useState(false)

  const [activeReceiver, setActiveReceiver] = useState(null)
  const [invalidReceivers, setInvalidReceivers] = useState([])

  const [fileList, setFileList] = useState([])
  const userAccounts = useSelector((state) => state.AccountsState.accounts)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [template, setTemplate] = useState(null)
  const [scheduledDate, setScheduledDate] = useState(
    splitMessage ? moment(splitMessage.scheduled_send_date).tz(user.time_zone) : null
  )
  const [ccReceivers, setCcReceivers] = useState([])
  const [receiverId, setReceiverId] = useState(null)
  const [to, setTo] = useState(null)
  const [subject, setSubject] = useState(splitMessage ? splitMessage.subject : null)
  const [body, setBody] = useState("<div>Hey {{ first_name }} <br /> <br /> </div>")
  const [oldSplitMessage, setOldSplitMessage] = useState(null)
  const [aiDraft, setAiDraft] = useState(null)
  const selectedContactId = useSelector(
    (state) => state.AppState.selected_contact_id
  )
  const [defaultScreenVisible, setDefaultScreenVisible] = useState(true)

  const stateRef = useRef({
    messages: {},
    editedMessages: {},
    receiverIdRef: null,
  })

  useEffect(() => {
    if (selected_template) {
      setTemplate(selected_template)
      setFileList(selected_template.attachments)
      const body = selected_template.body + "\n\n" + selectedAccount?.signature
      updateIndividualMessage({ subject: selected_template.subject, body: body })
      setSubject(selected_template.subject)
      setBody(body)
      if (selectedContactId) {
        handleChangeActiveReceiver(receivers[0])
      }
    }
    // eslint-disable-next-line
  }, [selected_template])

  useEffect(() => {
    if (ai_draft) {
      setAiDraft({
        subject: ai_draft.subject,
        body: ai_draft.body,
        preset_template_id: ai_draft.preset_template_id,
      })
      setBody(ai_draft.body)
      setSubject(ai_draft.subject)

      updateIndividualMessage({ subject: ai_draft.subject, body: ai_draft.body })
    }
    // eslint-disable-next-line
  }, [ai_draft])

  useEffect(() => {
    receivers.forEach((r) => {
      if (!_.isEmpty(r.email_addresses)) {
        validateEmail(
          (oldSplitMessage && oldSplitMessage[r.id]?.to) ||
            r.email_addresses[0].value,
          r.id
        )
      }
    })
    // eslint-disable-next-line
  }, [receivers])

  useEffect(() => {
    if (splitMessage) {
      stateRef.current.messages = initSplitMessage(splitMessage)
      setDefaultScreenVisible(false)
      updateIndividualMessage({ body: body, subject: subject }, null)

      setFileList(splitMessage.attachments)
      setBody(splitMessage.body)
      setTimeout(() => setOldSplitMessage(stateRef.current.messages), 500)
      const currentSelectedAccount = userAccounts.find(
        (a) => a.email === splitMessage.from
      )
      setSelectedAccount(currentSelectedAccount)
    } else {
      setSubject("Checking in")
      stateRef.current.messages["null"] = { subject: "Checking in" }
    }
    updateIndividualMessage(stateRef.current.messages[null], null)
    // eslint-disable-next-line
  }, [splitMessage])

  useEffect(() => {
    if (userAccounts) {
      const runningAccounts = userAccounts.filter((a) => a.state === "running")

      if (runningAccounts && runningAccounts.length && !splitMessage) {
        let email = runningAccounts.find((a) => a.default)?.email
        if (email === undefined) {
          email = runningAccounts[0].email
        }
        changeSelectedAccount(email)
      }
    }
    // eslint-disable-next-line
  }, [userAccounts])

  const resetIndividualMessages = () => {
    setSubject(stateRef.current.editedMessages["null"].subject)
    setCcReceivers([])
    setTo(null)
    stateRef.current.editedMessages = Object.fromEntries(
      Object.entries(stateRef.current.editedMessages).filter(
        ([key, value]) => key === "null"
      )
    )
  }

  const resetMessages = (field) => {
    Object.keys(stateRef.current.messages).forEach((key) => {
      if (key === "null") return
      stateRef.current.messages[key][field] = null
    })
  }

  const validateEmail = (email, id) => {
    const re = /^\S+@\S+\.\S+$/
    if (re.test(email)) {
      setInvalidReceivers(invalidReceivers.filter((r) => r !== id))
    } else if (invalidReceivers.indexOf(id) === -1) {
      invalidReceivers.push(id)
      setInvalidReceivers(invalidReceivers)
    }
  }

  const updateIndividualMessage = (message, messageKey = receiverId) => {
    if (message.to && messageKey) {
      validateEmail(message.to, messageKey)
    }

    stateRef.current.editedMessages = {
      ...stateRef.current.editedMessages,
      [messageKey]: {
        ...stateRef.current.editedMessages[messageKey],
        ...message,
      },
    }
  }

  const changeSelectedAccount = (v) => {
    const currentSelectedAccount = userAccounts.find((a) => a.email === v)
    const newBody = injectSignature(body, selectedAccount, currentSelectedAccount)
    setBody(newBody)
    updateIndividualMessage({ body: newBody, from: currentSelectedAccount?.email })
    setSelectedAccount(currentSelectedAccount)
  }

  const handleChangeActiveReceiver = (r) => {
    const { editedMessages, messages } = stateRef.current
    stateRef.current.receiverIdRef = r?.id || null
    setReceiverId(r?.id || null)
    setActiveReceiver(r)

    if (r) {
      let connexions_emails = []
      if (includeRelated && r.connexions.length > 0) {
        connexions_emails = r.connexions.map((c) => c.email).filter(Boolean) || []
      }

      let to = null
      if (editedMessages[r.id]?.to) {
        to = editedMessages[r.id].to
      } else if (messages[r.id]?.to) {
        to = messages[r.id].to
      } else if (r.email_addresses) {
        to = r.last_used_email || r.email_addresses[0].value
      }

      const from = editedMessages[null]?.from

      if (
        splitMessage &&
        (oldSplitMessage[null].body !== editedMessages[null].body ||
          oldSplitMessage[null].subject !== editedMessages[null].subject ||
          oldSplitMessage[null].from !== editedMessages[null].from)
      ) {
        let subject
        const cc = editedMessages[r.id]?.cc || messages[r.id]?.cc
        const uniqCC = [...new Set([...cc, ...connexions_emails])]

        let body

        if (oldSplitMessage[null].subject !== editedMessages[null].subject) {
          subject = editedMessages[r.id]?.subject || editedMessages[null]?.subject
        } else {
          subject = editedMessages[r.id]?.subject || messages[r.id]?.subject
        }

        if (oldSplitMessage[null].body !== editedMessages[null].body) {
          body = editedMessages[r.id]?.body || editedMessages[null]?.body
        } else {
          body = editedMessages[r.id]?.body || messages[r.id]?.body
        }

        if (oldSplitMessage[null].from !== editedMessages[null].from) {
        }

        const renderedBody = renderTemplate(r, body, includeRelated)

        updateIndividualMessage(
          {
            from: from,
            to: to,
            subject: subject,
            cc: uniqCC,
            body: renderedBody,
          },
          r.id
        )

        setSubject(subject)
        setCcReceivers(uniqCC)
        setBody(renderedBody)
      } else {
        const subject =
          editedMessages[r.id]?.subject ||
          messages[r.id]?.subject ||
          editedMessages[null]?.subject ||
          ""

        const cc = editedMessages[r.id]?.cc || messages[r.id]?.cc || []

        const uniqCC = [...new Set([...cc, ...connexions_emails])]

        const body =
          editedMessages[r.id]?.body ||
          messages[r.id]?.body ||
          editedMessages[null]?.body ||
          ""

        const renderedBody = renderTemplate(r, body, includeRelated)

        updateIndividualMessage(
          {
            from: from,
            to: to,
            subject: subject,
            cc: uniqCC,
            body: renderedBody,
          },
          r.id
        )

        setSubject(subject)
        setCcReceivers(uniqCC)
        setBody(renderedBody)
      }
      setTo(to)
      dispatch(setVisibleContactID(r.id, r.slug))
      dispatch(setContactDrawerVisible(true))
      dispatch(setContactDrawerMode("view"))
    } else {
      dispatch(setVisibleContactID(null))
      dispatch(setContactDrawerVisible(false))
      if (selectedAccount) {
        const body = editedMessages[null]?.body || template?.body || ""
        const subject = editedMessages[null]?.subject || ""
        updateIndividualMessage({ body: body, subject: subject, cc: [] }, null)
        setSubject(subject)
        setCcReceivers([])
        setBody(body)
      }
    }
  }

  const generateMessages = () => {
    const { editedMessages, messages } = stateRef.current
    let _messages = editedMessages

    Object.keys(_messages).forEach((k) => {
      if (k === "null") return
      if (!receivers.find((r) => r.id === parseInt(k))) {
        delete _messages[k]
      }
    })

    receivers.forEach((r) => {
      const receiversToIgnore = _.isEmpty(r.email_addresses) || r.moved_to_cc

      if (receiversToIgnore) {
        if (r.id in _messages) {
          delete _messages[r.id]
        }
      } else {
        if (!(r.id in _messages)) {
          const connexion_emails =
            r.connexions.map((c) => c.email).filter(Boolean) || []

          if (splitMessage) {
            _messages[r.id] = {
              from: editedMessages[null].from,
              cc: includeRelated ? connexion_emails : null,
              subject:
                editedMessages[r.id]?.subject ||
                messages[r.id]?.subject ||
                editedMessages[null].subject,
              body: editedMessages[r.id]?.body || messages[r.id]?.body || null,
              person_id: r.id,
              message_id: oldSplitMessage[r.id].message_id,
              to: editedMessages[r.id]?.to || oldSplitMessage[r.id].to,
              first_names: includeRelated ? getFirstNames(r) : null,
            }
          } else {
            let to
            if (editedMessages[r.id]?.to) {
              to = editedMessages[r.id].to
            } else if (r.email_addresses) {
              to = r.last_used_email || r.email_addresses[0].value
            }
            _messages[r.id] = {
              from: editedMessages[null].from,
              subject: editedMessages[null].subject,
              body: null,
              to: to,
              cc: includeRelated ? connexion_emails : null,
              first_names: includeRelated ? getFirstNames(r) : null,
            }
          }
        } else {
          if (splitMessage) {
            _messages[r.id] = {
              ...messages[r.id],
              to: _messages[r.id]?.to || messages[r.id].to,
              body: _messages[r.id]?.body,
              subject: _messages[r.id]?.subject || editedMessages[null].subject,
              message_id: oldSplitMessage[r.id].message_id,
            }
          }
        }
      }
    })
    if (oldSplitMessage) {
      _messages[null].split_message_id = oldSplitMessage[null].split_message_id
    }
    return _messages
  }

  return (
    <div className={"MessageCreator_Wrapper"}>
      <ReceiversListComponent
        receivers={receivers}
        invalidReceivers={invalidReceivers}
        onSetReceivers={setReceivers}
        activeReceiver={activeReceiver}
        onSetActiveReceiver={handleChangeActiveReceiver}
        onRemoveReceiver={(id) => {
          if (receivers.length <= 1) {
            dispatch(setSplitMessageSenderVisible(false))
          }
          setInvalidReceivers(invalidReceivers.filter((r) => r !== id))
          if (activeReceiver?.id === id) {
            const validReceivers = receivers.filter(
              (r) => !_.isEmpty(r.email_addresses) && !r.moved_to_cc
            )
            const idx = validReceivers.indexOf(activeReceiver)
            handleChangeActiveReceiver(validReceivers[idx - 1])
          }
          delete stateRef.current.messages[id]
        }}
        onResetIndividualMessages={resetIndividualMessages}
        includeRelatedContacts={includeRelated}
      />
      <div className={"MessageCreator_FormWrapper"}>
        <MessageForm
          splitMessage={!selectedContactId}
          isDefaultScreenVisible={defaultScreenVisible}
          receiver={receiverId}
          invalidReceivers={invalidReceivers}
          message={{ subject: subject, to: to, from: selectedAccount?.email }}
          updateMessage={(values) => {
            if (activeReceiver) {
              updateIndividualMessage(values, activeReceiver.id)
              setTo(values.to)
            }

            if (!activeReceiver || !splitMessage) {
              if (values.subject) {
                resetMessages("subject")
              }
              updateIndividualMessage(values, null)
            }

            setSubject(values.subject)
          }}
          changeSelectedAccount={(account) => {
            changeSelectedAccount(account)
          }}
          ccReceivers={ccReceivers}
          setCcReceiversForEach={() => {
            setIncludeRelatedContacts(true)
            const receiverIdsWithConnexions = new Set()
            const newReceivers = receivers.map((receiver) => {
              if (
                receiver.connexions.length > 0 &&
                receiver.connexions.some((connexion) =>
                  receiverIdsWithConnexions.has(connexion.person_id)
                )
              ) {
                return { ...receiver, moved_to_cc: true }
              } else {
                receiverIdsWithConnexions.add(receiver.id)

                return receiver
              }
            })

            setReceivers(newReceivers)
          }}
          setCcReceivers={(cc) => {
            updateIndividualMessage({ cc: cc }, receiverId)
            setCcReceivers(cc)
          }}
          includedRelatedContacts={includeRelated}
          closeDefaultScreen={() => {
            setDefaultScreenVisible(false)
          }}
        />
        {defaultScreenVisible ? null : (
          <>
            <TinyMCUEditor
              templateId={template?.id}
              accountId={selectedAccount?.id}
              receiverId={receiverId}
              toolbarId={"SplitMessage"}
              updateBody={(html) => {
                // update the stored message if body is different or message is not yet stored
                // this is disgusting
                const { messages, editedMessages, receiverIdRef } = stateRef.current

                if (
                  !messages[receiverIdRef] ||
                  messages[receiverIdRef]?.body !== html ||
                  (editedMessages[receiverIdRef] &&
                    editedMessages[receiverIdRef].body !== html) ||
                  !receiverIdRef
                ) {
                  updateIndividualMessage({ body: html }, receiverIdRef)
                  if (
                    !receiverIdRef &&
                    editedMessages[null] &&
                    editedMessages[null].body !== html
                  ) {
                    resetMessages("body")
                  }
                }
                setBody(html)
              }}
              extras={[
                <SchedulerDateButton
                  activeReceiver={activeReceiver}
                  scheduledDate={scheduledDate}
                  onSetScheduledDate={(date) => {
                    setScheduledDate(date)
                  }}
                />,
                <AttachmentsUpload
                  fileList={fileList}
                  handleFileList={(newValues) => {
                    setFileList(newValues)
                  }}
                />,
              ]}
              body={body}
            />
            <div className={"MessageCreator_FooterButtons"}>
              <div
                onClick={() => {
                  if (invalidReceivers.length || loading) {
                    return false
                  }
                  if (activeReceiver) {
                    const tempScheduledDate =
                      scheduledDate &&
                      (moment().tz(user.time_zone).isBefore(scheduledDate)
                        ? scheduledDate
                        : null)
                    const generatedMessages = generateMessages()
                    if (tempScheduledDate && splitMessage) {
                      dispatch(
                        updateScheduledSplitMessage(
                          generatedMessages,
                          fileList,
                          tempScheduledDate
                        )
                      )
                    } else {
                      dispatch(
                        sendSplitMessage(
                          generatedMessages,
                          fileList,
                          tempScheduledDate,
                          ccReceivers,
                          aiDraft
                        )
                      )
                      dispatch(setInitCC([]))
                    }
                    if (splitMessage) {
                      onSubmit()
                      onCancel()
                    }
                    dispatch(setMessageDraft(null))
                    dispatch(setVisibleContactID(null))
                    dispatch(setContactDrawerVisible(false))
                    setShowConfetti(true)
                  } else {
                    const validReceivers = receivers.filter(
                      (r) => !_.isEmpty(r.email_addresses) && !r.moved_to_cc
                    )
                    handleChangeActiveReceiver(validReceivers[0])
                  }
                }}
                className={`MessageCreator_SubmitButton ${
                  (invalidReceivers.length || loading) && "disabled"
                }`}
              >
                {activeReceiver ? (scheduledDate ? "Schedule" : "Send") : "Next"}
              </div>
              {splitMessage && !activeReceiver && (
                <div
                  onClick={() => {
                    dispatch(
                      deleteScheduledSplitMessage(splitMessage.id, splitMessage.from)
                    )
                    onSubmit()
                    onCancel()
                  }}
                  className={"MessageCreator_CancelButton"}
                >
                  Cancel Split Message
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default MessageCreator
