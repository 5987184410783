import React, { useEffect, useState } from "react"
import "./ErrorOverlay.styles.scss"
import { Divider, Tooltip } from "antd"
import Icon from "@ant-design/icons"
import CaretLeftIcon from "../../../Icons/CaretLeft.icons"
import { useHistory } from "react-router-dom"
const ErrorOverlay = ({ handleClose }) => {
  const [reason, setReason] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (history.location.pathname.match(/.*404.*/)) {
      setReason({
        title: "Person not found",
        message: "This person could not be found in your database",
      })
    } else {
      setReason({
        title: "Error",
        message: "An error occurred while trying to fetch this person",
      })
    }
  }, [])

  return (
    <div className={"ErrorOverlay"}>
      <div className={"ErrorOverlay_Header"}>
        <Tooltip title="Back" placement="left">
          <Icon component={CaretLeftIcon} onClick={handleClose} />
        </Tooltip>
      </div>
      <h1>{reason?.title}</h1>
      <Divider />
      <div style={{ padding: 10 }}>
        <p>{reason?.message}</p>
      </div>
      <Divider />
    </div>
  )
}

export default ErrorOverlay
